.AlertMessage {
  color: #de6e61;
  visibility: hidden;
  min-height: 38px;

  &.visible {
    visibility: visible;
  }

  &.success {
    color: #51b474;
  }
}
